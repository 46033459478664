import React, { useEffect, useState } from "react";

import { resumoHome } from "../../api/api";
function Home() {
  const [resumo, setResumo] = useState([]);

  useEffect(() => {
    const fetchDados = async () => {
      const result = await resumoHome();
      setResumo(result);
    };

    fetchDados();
  }, []);

  return (
    <div className="content">
      <h1>Home</h1>
      <div>
        <h2>Dados dos Artistas</h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#f4f4f4" }}>
              <th
                style={{
                  padding: "12px",
                  border: "1px solid #ddd",
                  textAlign: "left",
                }}
              >
                Nome (Alias)
              </th>
              <th
                style={{
                  padding: "12px",
                  border: "1px solid #ddd",
                  textAlign: "left",
                }}
              >
                Total
              </th>
              <th
                style={{
                  padding: "12px",
                  border: "1px solid #ddd",
                  textAlign: "left",
                }}
              >
                Completos
              </th>
              <th
                style={{
                  padding: "12px",
                  border: "1px solid #ddd",
                  textAlign: "left",
                }}
              >
                Incompletos
              </th>
              <th
                style={{
                  padding: "12px",
                  border: "1px solid #ddd",
                  textAlign: "left",
                }}
              >
                Respondidos
              </th>
            </tr>
          </thead>
          <tbody>
            {resumo.map((artista) => (
              <tr key={artista._id}>
                <td
                  style={{ padding: "12px", border: "1px solid #ddd" }}
                >{`${artista.name} (${artista.alias})`}</td>
                <td style={{ padding: "12px", border: "1px solid #ddd" }}>
                  {artista.total}
                </td>
                <td style={{ padding: "12px", border: "1px solid #ddd" }}>
                  {artista.completos}
                </td>
                <td style={{ padding: "12px", border: "1px solid #ddd" }}>
                  {artista.incompletos}
                </td>
                <td style={{ padding: "12px", border: "1px solid #ddd" }}>
                  {artista.respondidos}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Home;
