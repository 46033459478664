// App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/routes";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GA_TOKEN);
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: true, track_pageview: true, persistence: 'localStorage'});

function App() {
  return (
    <Router>
      <div className="app-container">
        <ToastContainer />
        <AppRoutes />
      </div>
    </Router>
  );
}

export default App;
