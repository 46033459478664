import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { loginUser, checkToken, logout } from "../api/api";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await loginUser(email, password);

      localStorage.setItem("token", data.token);
      const decodedToken = jwtDecode(data.token);
      localStorage.setItem("user", JSON.stringify(decodedToken));

      if (decodedToken.role === "artist") {
        navigate("/artist");
        toast.success("Bem Vindo!");
      } else if (decodedToken.role === "client") {
        navigate("/client");
        toast.success("Bem Vindo!");
      } else if (decodedToken.role === "admex") {
        navigate("/admex");
        toast.success("HAIL");
      }
    } catch (error) {
      console.error("Erro ao fazer login", error);
      if (error.response) {
        // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
        toast.error(error.response.data.msg);
      } else if (error.request) {
        // O pedido foi feito, mas nenhuma resposta foi recebida
        toast.error("Erro ao fazer o pedido. Por favor, tente novamente.");
        logout()
      } else {
        // Algo aconteceu na configuração do pedido que disparou um erro
        toast.error("Erro desconhecido. Por favor, tente novamente.");
        logout()
      }
      
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = localStorage.getItem("token");
      try {
        if (token) {
          const result = await checkToken();
          if (result) {
            const decodedToken = jwtDecode(token);
            localStorage.setItem("user", JSON.stringify(decodedToken));
            if (decodedToken.role === "artist") {
              navigate("/artist");
              toast.success("Bem Vindo!");
            } else if (decodedToken.role === "client") {
              navigate("/client");
              toast.success("Bem Vindo!");
            } else if (decodedToken.role === "admex") {
              navigate("/admex");
              toast.success("HAIL");
            }
          }
        }
      } catch (error) {
        console.error("Erro ao fazer login", error);
        if (error.response) {
          // O pedido foi feito e o servidor respondeu com um status fora do intervalo de 2xx
          toast.error(error.response.data.msg);
        } else if (error.request) {
          // O pedido foi feito, mas nenhuma resposta foi recebida
          toast.error("Erro ao fazer o pedido. Por favor, tente novamente.");
          logout()
        } else {
          // Algo aconteceu na configuração do pedido que disparou um erro
          toast.error("Erro desconhecido. Por favor, tente novamente.");
          logout()
        }
      }
    };
    fetchToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="login">
      <div className="frame">
        <img className="asset" alt="Asset" src="/img/logo.svg"/>
        <div className="div">
          <form className="form-login" onSubmit={handleSubmit}>
            <div className="frame-2">
              <div className="frame-3">
                <div className="text-wrapper">E-mail</div>
                <div className="div-wrapper">
                  <input
                    className="text-wrapper-2"
                    placeholder="exemplo@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="frame-3">
                <div className="text-wrapper">Senha</div>
                <div className="div-wrapper">
                  <input
                    className="text-wrapper-2"
                    placeholder="Digite sua senha"
                    type={"password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="frame-4" onClick={handleSubmit}>
              <div className="text-wrapper-3">Entrar</div>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
